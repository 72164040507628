import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import './style.css'

import { cpfMask } from './cpfMask'
import { phoneMask } from './phoneMask'
import { isValidCpf } from './isValidCpf'

import wompstar from '../images/wompstar.png'
import wompfull from '../images/wompfull.png'

import loadingImage from '../images/loading.gif'

const FormContainer = styled.form`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  padding: 20px;
  border-radius: 5px;
`

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.text`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  width: 120px;
  padding: 0 10px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  height: 40px;
`

const Label = styled.label``

const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #2c73d2;
  color: white;
  height: 42px;
`

const Form = ({ onEdit, setOnEdit }) => {
  const [loading, setLoading] = useState(false)

  const [imageVisible1, setImageVisible1] = useState(false)
  const [imageVisible2, setImageVisible2] = useState(false)

  const [paymentVisible, setPaymentVisible] = useState(false) // Estado para controlar a visibilidade do pagamento
  const [selectedButton, setSelectedButton] = useState(null)

  // Função para controlar a visibilidade do pagamento
  const togglePaymentVisibility = () => {
    setPaymentVisible(!paymentVisible) // Inverte o estado de visibilidade
  }

  // Função para alternar entre os botões e definir o botão selecionado
  /*  const handleButtonClick = buttonId => {
    setSelectedButton(buttonId)
    if (!paymentVisible) {
      togglePaymentVisibility() // Ativa a visibilidade do pagamento apenas se estiver oculto
    }
  } */

  // Função para alternar entre os botões e definir o botão selecionado
  const handleButtonClick = (buttonId, chargeTotal) => {
    setSelectedButton(buttonId)
    setChargeTotal(chargeTotal)

    // Registre o valor do plano no console com base no botão clicado
    //console.log('Valor selecionado:', chargeTotal)

    // Registre o valor do plano no console com base no botão clicado
    let value = ''
    if (buttonId === 'superStar') {
      value = '999'
    } else if (buttonId === 'superFull') {
      value = '13999'
    }
    console.log('Valor selecionado:', value)

    if (!paymentVisible) {
      togglePaymentVisibility() // Ativa a visibilidade do pagamento apenas se estiver oculto
    }
    // Chame a função handleSubmitApi4 com o chargeTotal como argumento
    handleSubmitApi4(chargeTotal)
  }

  const handleClick = newValue => {
    setSubscriptionUid(newValue)
  }

  const toggleImageVisibility1 = () => {
    setImageVisible1(!imageVisible1)
  }

  const toggleImageVisibility2 = () => {
    setImageVisible2(!imageVisible2)
  }

  //Data Atual - STARTED DATE
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)

  //6 MESES
  const semesterDate = new Date()
  semesterDate.setDate(semesterDate.getDate() + 2)
  semesterDate.setMonth(semesterDate.getMonth() + 5)

  //12 MESES
  const yearlyDate = new Date()
  yearlyDate.setDate(yearlyDate.getDate() + 2)
  yearlyDate.setMonth(yearlyDate.getMonth() + 11)

  const [chargeTotal, setChargeTotal] = useState('')

  const ref = useRef()
  //const [phone, setPhone] = useState('')

  const getToken = async () => {
    const clientId = '2d069be2-a2f7-45db-89a7-b037121e201a'
    const clientSecret = 'I1hi1B13MYB3ePuh961wTaNzdLjTlErfeI/mmXXb7a8='
    const authString = `${clientId}:${clientSecret}`
    const base64AuthString = btoa(authString)

    try {
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
      const endpointUrl =
        'https://cieloecommerce.cielo.com.br/api/public/v2/token'
      const response = await axios.post(proxyUrl + endpointUrl, null, {
        headers: {
          Authorization: `Basic ${base64AuthString}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })

      //console.log('Token:', response.data.access_token)

      return response.data.access_token // Retorna o token
    } catch (error) {
      console.error('Erro ao obter o token de acesso:', error.response.data)
      throw error
    }
  }

  const handleSubmitApi4 = async (event, token) => {
    event.preventDefault()

    // Calcula a data daqui a 1 ano
    const currentDate = new Date()
    const nextYear = currentDate.getFullYear() + 1
    const nextYearDate = new Date(
      nextYear,
      currentDate.getMonth(),
      currentDate.getDate()
    )

    const requestData = {
      type: 'Recurrent',
      name: 'Womp Play',
      description: 'Womp Play',
      price: chargeTotal,
      expirationDate: '2027-06-19',
      quantity: 2,
      sku: 'Womp Play',
      shipping: {
        type: 'WithoutShipping',
        name: 'Womp Play',
        price: '0'
      },
      recurrent: {
        interval: 'Monthly',
        endDate: nextYearDate.toISOString().split('T')[0] // Formata a data para o formato ISO sem a hora
      },
      softDescriptor: '1'
    }

    try {
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
      const url = 'https://cieloecommerce.cielo.com.br/api/public/v1/products/'
      const response = await axios.post(proxyUrl + url, requestData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}` // Use the token here
        }
      })

      const shortUrl = response.data.shortUrl
      //console.log('Short URL:', shortUrl)

      if (shortUrl) {
        window.open(shortUrl, '_blank')
      } else {
        console.error('URL de redirecionamento não encontrada.')
      }

      console.log(response.data)

      // Extrair o ID da resposta
      const productId = response.data.id
      console.log('Product ID:', productId)

      // Chamar handleSubmitApiGetPayment e passar o productId como parâmetro
      await handleSubmitApiGetPayment(productId)
      // Salvar o ID para uso posterior
      // Agora você pode usar a variável productId em outras partes do seu código

      // Redirecionar para o shortUrl

      return productId // Retorna o productId após a extração
    } catch (error) {
      console.error(error)
    }
  }

  const POLLING_INTERVAL = 10000 // Tempo em milissegundos (10 segundos)
  let pollingIntervalId = null // Variável para armazenar o ID do intervalo
  let toastShown = false

  const handleSubmitApiGetPayment = async (productId, token, event) => {
    const poll = async () => {
      try {
        const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
        const url = `https://cieloecommerce.cielo.com.br/api/public/v1/products/${productId}/payments`

        console.log('URL da solicitação:', proxyUrl + url)

        const response = await axios.get(proxyUrl + url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Use o token aqui
          }
        })
        // Verifique se a resposta possui a estrutura correta
        if (
          response.data &&
          response.data.orders &&
          response.data.orders.length > 0 &&
          response.data.orders[0].payment
        ) {
          const status = response.data.orders[0].payment.status

          console.log('Status do pagamento:', status)

          if (status === 'Paid' && !toastShown) {
            // O pagamento foi aprovado, você pode realizar a ação desejada aqui
            console.log('Pagamento Aprovado!')
            clearInterval(pollingIntervalId) // Cancela o intervalo

            // Chame a função handleSubmitApi2 aqui
            const response1 = await axios.post(
              'https://hub.multtv.tv.br/hub/middleware_multtv/createSubscriber',
              {
                subscriberOperatorUid:
                  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVXNlcm5hbWUiOiJXT01QIiwiYXV0aG9yaXphdGlvbiI6Ik9QRVJBVE9SIiwiaWF0IjoxNjY4Nzc5NzI5fQ.zUUCsMnT85otl4atT8BGPH_Io0e-4rGPDn6FCophKlc',
                subscriberUser,
                subscriberPhrase,
                subscriberParentalPIN: '1234',
                subscriberData: {
                  subscriberStatus: 'ACTIVE'
                }
              }
            )
            const subscriberUid = response1.data.subscriberUid

            console.log(response1.data)

            const response2 = await axios.post(
              'https://hub.multtv.tv.br/hub/middleware_multtv/linkSubscriptionPlanWithSubscriber',
              {
                subscriberOperatorUid:
                  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVXNlcm5hbWUiOiJXT01QIiwiYXV0aG9yaXphdGlvbiI6Ik9QRVJBVE9SIiwiaWF0IjoxNjY4Nzc5NzI5fQ.zUUCsMnT85otl4atT8BGPH_Io0e-4rGPDn6FCophKlc',
                subscriberUid,
                subscriptionUid
              }
            )
            console.log(response2.data)

            const response3 = await axios.post(
              'https://hub.multtv.tv.br/hub/middleware_multtv/changeSubscriberStatus',
              {
                subscriberOperatorUid:
                  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVXNlcm5hbWUiOiJXT01QIiwiYXV0aG9yaXphdGlvbiI6Ik9QRVJBVE9SIiwiaWF0IjoxNjY4Nzc5NzI5fQ.zUUCsMnT85otl4atT8BGPH_Io0e-4rGPDn6FCophKlc',
                subscriberUid,
                subscriberStatus: 'ACTIVE'
              }
            )
            console.log(response3.data)

            setLoading(false)
            toast.success('Pagamento aprovado com sucesso!', {
              autoClose: 300000 // 300000 milissegundos = 5 segundos
            })
            toastShown = true // Define a variável para evitar exibir novamente

            return
          } else if (status === 'Denied' && !toastShown) {
            // O pagamento foi negado, você pode realizar outra ação aqui se necessário
            console.log('Pagamento Negado.')
            clearInterval(pollingIntervalId) // Cancela o interval

            setLoading(false) // Defina o estado de carregamento como falso aqui
            toast.error('Pagamento recusado.', {
              autoClose: 300000 // 300000 milissegundos = 5 segundos
            })
            toastShown = true // Define a variável para evitar exibir novamente

            return
          }
        } else {
          console.error('Resposta da API não possui a estrutura esperada.')
        }
        console.log(response.data)
        // Aguarde um intervalo antes da próxima consulta (por exemplo, 1 minuto)
        // Continue com a próxima iteração do polling após o tempo de espera
        setTimeout(() => poll(), 60 * 1000) // 1 minuto em milissegundos
      } catch (error) {
        console.error('Erro na chamada da API:', error)
        // Trate erros de consulta, se necessário
      }
    }
    pollingIntervalId = setInterval(poll, POLLING_INTERVAL)
  }

  //Dados enviados para o Middleware MultTV
  const [subscriberUser, setSubscriberUser] = useState('')
  const [subscriberPhrase, setSubscriberPhrase] = useState('')
  const [subscriptionUid, setSubscriptionUid] = useState('')

  //Dados enviados para o Banco de dados Womp
  const [nomeCliente, setNomeCliente] = useState('')
  const [cpfCliente, setCpfCliente] = useState('')
  const [emailCliente, setEmailCliente] = useState('')
  const [contatoCliente, setContatoCliente] = useState('')
  //const [senhaCliente, setSenhaCliente] = useState('')

  const [confirmPassword, setConfirmPassword] = useState('')
  const [isMatch, setIsMatch] = useState(false)
  const [isTouched, setIsTouched] = useState(false)

  const [cpfValido, setCpfValido] = useState(true)

  useEffect(() => {
    if (cpfCliente !== '') {
      if (isValidCpf(cpfCliente)) {
        setCpfValido(true)
      } else {
        setCpfValido(false)
      }
    }
  }, [cpfCliente])

  function handleCpfChange(event) {
    const { value } = event.target
    setCpfCliente(cpfMask(value))
  }

  function handleConfirmPasswordChange(e) {
    setConfirmPassword(e.target.value)
    setIsMatch(subscriberPhrase === e.target.value)
  }

  function handleBlur() {
    setIsTouched(true)
  }

  // definir a função que verifica se o formulário está completo
  const handleFormCompletion = () => {
    const isNomeClienteValid = Boolean(nomeCliente.trim())
    const isCpfClienteValid = Boolean(cpfCliente.trim())
    const isEmailClienteValid = Boolean(
      subscriberUser.trim() && emailCliente.trim()
    )
    const isContatoClienteValid = Boolean(contatoCliente.trim())
    const isSenhaClienteValid = Boolean(subscriberPhrase.trim())
    const isConfirmarSenhaClienteValid = Boolean(confirmPassword.trim())

    const isFormValid =
      isNomeClienteValid &&
      isCpfClienteValid &&
      isEmailClienteValid &&
      isContatoClienteValid &&
      isSenhaClienteValid &&
      isConfirmarSenhaClienteValid

    return isFormValid
  }

  const superStar = '999'
  const superFull = '13999'

  /*   const handleSubscriptionValueChange = event => {
    const value = event.target.value
    setSubscriptionValue(value)

    let newChargeTotal = ''
    if (value === superStar) {
      newChargeTotal = '999'
    }
    if (value === superFull) {
      newChargeTotal = '13999'
    }
    setChargeTotal(newChargeTotal)

    // Adicione um console.log para registrar o valor
    console.log('Valor selecionado:', value)
  } */

  function handleContatoChange(event) {
    setContatoCliente(phoneMask(event.target.value))
  }

  /*   const handleSubmitApi1 = async e => {
    e.preventDefault()
    try {
      const response1 = await axios.post('http://localhost:8800/', {
        nomeCliente,
        cpfCliente,
        emailCliente,
        contatoCliente,
        senhaCliente
      })
      console.log(response1.data)
    } catch (error) {
      console.log(error)
    }

    e.target.reset()
    setOnEdit(null)
  } */

  //ENVIA PARA A API MULTTV

  /*
  const handleSubmitApi2 = async event => {
    try {
      event.preventDefault()
      const response1 = await axios.post(
        'https://hub.multtv.tv.br/hub/middleware_multtv/createSubscriber',
        {
          subscriberOperatorUid:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVXNlcm5hbWUiOiJXT01QIiwiYXV0aG9yaXphdGlvbiI6Ik9QRVJBVE9SIiwiaWF0IjoxNjY4Nzc5NzI5fQ.zUUCsMnT85otl4atT8BGPH_Io0e-4rGPDn6FCophKlc',
          subscriberUser,
          subscriberPhrase,
          subscriberParentalPIN: '1234',
          subscriberData: {
            subscriberStatus: 'ACTIVE'
          }
        }
      )
      const subscriberUid = response1.data.subscriberUid

      console.log(response1.data)

      const response2 = await axios.post(
        'https://hub.multtv.tv.br/hub/middleware_multtv/linkSubscriptionPlanWithSubscriber',
        {
          subscriberOperatorUid:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVXNlcm5hbWUiOiJXT01QIiwiYXV0aG9yaXphdGlvbiI6Ik9QRVJBVE9SIiwiaWF0IjoxNjY4Nzc5NzI5fQ.zUUCsMnT85otl4atT8BGPH_Io0e-4rGPDn6FCophKlc',
          subscriberUid,
          subscriptionUid: subscriptionUid
        }
      )
      console.log(response2.data)

      const response3 = await axios.post(
        'https://hub.multtv.tv.br/hub/middleware_multtv/changeSubscriberStatus',
        {
          subscriberOperatorUid:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVXNlcm5hbWUiOiJXT01QIiwiYXV0aG9yaXphdGlvbiI6Ik9QRVJBVE9SIiwiaWF0IjoxNjY4Nzc5NzI5fQ.zUUCsMnT85otl4atT8BGPH_Io0e-4rGPDn6FCophKlc',
          subscriberUid,
          subscriberStatus: 'ACTIVE'
        }
      )
      console.log(response3.data)
    } catch (error) {
      console.log(error)
    }
  }
  */

  /*   const handleSubmitTeste = async event => {
    return new Promise(async (resolve, reject) => {
      try {
        // Simule um atraso de 5 segundos
        await new Promise(resolve => setTimeout(resolve, 20 * 60 * 1000)) // 20 minutos em milissegundos

        // Após o atraso, retorne um status "OK"
        const successfulResponse = {
          status: 'OK',
          message: 'Ação bem-sucedida'
          // Outros dados relevantes que você deseja incluir na resposta
        }

        console.log('Resposta bem-sucedida:', successfulResponse)

        // Resolva a promessa com a resposta bem-sucedida
        resolve(successfulResponse)
      } catch (error) {
        console.error(error)
        // Rejeite a promessa em caso de erro
        reject(error)
      }
    })
  } */

  const handleSubmit = async event => {
    event.preventDefault()

    const isFormValid = handleFormCompletion()

    if (isFormValid) {
      setLoading(true) // Ativar o estado de carregamento

      try {
        const token = await getToken() // Obtenha o token aqui
        const productId = await handleSubmitApi4(event, token)

        if (productId) {
          await handleSubmitApiGetPayment(productId, token)

          setStep(3)
        } else {
          setLoading(false)
          toast.error(
            'Ocorreu um erro interno em nossos servidores. Tente novamente mais tarde.'
          )
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
        toast.error(
          'Ocorreu um erro interno em nossos servidores. Tente novamente mais tarde.'
        )
      }
    } else {
      toast.error('Por favor, preencha todos os campos.')
    }
  }

  const [step, setStep] = useState(1)
  const handleNext = () => {
    const isFormValid = handleFormCompletion()
    if (isFormValid) {
      setStep(prevStep => prevStep + 1)
    } else {
      toast.warning('Por favor, preencha todos os campos.')
    }
  }

  const handleBack = () => {
    setStep(prevStep => prevStep - 1)
  }

  return (
    <FormContainer
      className="form"
      ref={ref}
      onSubmit={handleSubmit}
      autocomplete="off"
    >
      {step === 1 && (
        <div>
          <div>
            <div className="div-input">
              <InputArea className="input-area">
                <Label className="label">Nome Completo</Label>
                <Input
                  className="input"
                  name="nomeCliente"
                  placeholder="Ex: João Silva"
                  value={nomeCliente}
                  onChange={event => setNomeCliente(event.target.value)}
                  required
                />
              </InputArea>
            </div>
            <div className="div-input">
              <InputArea>
                <Label className="label">CPF</Label>
                <Input
                  className="input"
                  name="cpfCliente"
                  placeholder="Ex: 999.999.999-99"
                  maxLength={14}
                  required
                  value={cpfCliente}
                  onChange={event => {
                    setCpfCliente(event.target.value)
                    handleCpfChange(event)
                  }}
                />
              </InputArea>
            </div>
            {!cpfValido && <p className="warning-message">CPF Inválido</p>}
            <div className="div-input">
              <InputArea>
                <Label className="label">E-mail</Label>
                <Input
                  className="input"
                  name="emailCliente"
                  type="email"
                  placeholder="Ex: seuemail@gmail.com"
                  value={subscriberUser}
                  onChange={e => {
                    setSubscriberUser(e.target.value)
                    setEmailCliente(e.target.value)
                  }}
                />
              </InputArea>
            </div>
            <div className="div-input">
              <InputArea>
                <Label className="label">Celular</Label>
                <Input
                  className="input"
                  maxLength="15"
                  name="contatoCliente"
                  placeholder="Ex: 21999999999"
                  type="text"
                  value={contatoCliente}
                  onChange={event => {
                    setContatoCliente(event.target.value)
                    handleContatoChange(event)
                  }}
                  required
                />
              </InputArea>
            </div>
            <div className="div-input">
              <InputArea>
                <Label className="label">Senha</Label>
                <Input
                  className="input"
                  maxLength="15"
                  name="senhaCliente"
                  type="password"
                  value={subscriberPhrase}
                  onChange={e => {
                    setSubscriberPhrase(e.target.value)
                    //setSenhaCliente(e.target.value)
                  }}
                  onBlur={handleBlur}
                  required
                />
              </InputArea>
            </div>
            <div className="div-input">
              <InputArea>
                <Label className="label">Confirmar senha</Label>
                <Input
                  className="input"
                  maxLength="15"
                  name="confirmarSenhaCliente"
                  type="password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  onBlur={handleBlur}
                  required
                />
              </InputArea>
            </div>
            {isTouched && !isMatch && (
              <p className="warning-message">As senhas não coincidem</p>
            )}
            <div className="button-container">
              <Button type="button" onClick={handleNext}>
                Continuar
              </Button>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <InputArea>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Label className="label1">Escolha o seu Plano</Label>
                </div>
                <div className="div-input-plano">
                  <div className="input-plano">
                    <div className="div-input-paymentsinfo">
                      <button
                        className={`toggle-button ${
                          selectedButton === 'superStar' ? 'selected' : ''
                        }`}
                        type="button" // Add this attribute
                        onClick={() => {
                          handleClick('335')
                          handleButtonClick('superStar', '999')
                        }}
                        value={superStar}
                      >
                        <div className="div-choose-plano">
                          <img
                            src={wompstar}
                            className="img-channels"
                            alt="Descrição da imagem"
                          />

                          <div className="div-radio-plan">
                            <Text className="label1">R$ 9,99</Text>
                          </div>
                        </div>
                      </button>
                      <div className="div-value-plan">
                        <button
                          className="toggle-image-button"
                          type="button" // Add this attribute
                          onClick={toggleImageVisibility1}
                        >
                          SAIBA MAIS
                        </button>
                      </div>
                    </div>
                    {imageVisible1 && (
                      <div>
                        <h1 className="div-h1-title">CANAIS DISPONÍVEIS:</h1>
                        <h2 className="div-h2-sub">Abertos:</h2>
                        <p className="div-p">
                          TV Encontro das Águas, Record TV, TV A Crítica, Rede
                          Amazônica, TV Norte Amazonas, Rede, Brasil, Band,
                          RedeTV, Rede CNT, TVT, TV Brasil HD, TV Brasil 2
                        </p>
                        <h2 className="div-h2-sub">Agro:</h2>
                        <p className="div-p">
                          TV Milagro, Terra Viva, Canal do Boi, Agro Canal
                        </p>
                        <h2 className="div-h2-sub">Ecommerce:</h2>
                        <p className="div-p">Polishop:</p>
                        <h2 className="div-h2-sub">Educativos:</h2>
                        <p className="div-p">
                          Educação Infantil e dos Anos Iniciais, Ensino Médio e
                          EJA, Anos Finais e EJA, TV Escola
                        </p>
                        <h2 className="div-h2-sub">Governamentais:</h2>
                        <p className="div-p">
                          TV Justiça, TV Câmara Municipal Manaus, TV Assembleia,
                          TV Câmara, TV Senado
                        </p>
                        <h2 className="div-h2-sub">Música:</h2>
                        <p className="div-p">TV Tiradentes</p>
                        <h2 className="div-h2-sub">Notícias:</h2>
                        <p className="div-p">Record News, TV Jovem Pan News</p>
                        <h2 className="div-h2-sub">Religiosos:</h2>
                        <p className="div-p">
                          12 Apóstolos, Boa Vontade, Rede Século 21, TV Pai
                          Eterno, Boas Novas, Rede Gênesis, RIT, TV Mundo Maior,
                          Ideal TV, Canção Nova, TV Novo Tempo, Rede Vida, Rede
                          Família, Rede Super, TV Aparecida
                        </p>
                        <h2 className="div-h2-sub">Variedades:</h2>
                        <p className="div-p">
                          TV Cultura, Womp TV, Amazonsat, TV Gazeta, SescTV,
                          Canal Saúde
                        </p>
                        <h2 className="div-h2-sub">Internacionais:</h2>
                        <p className="div-p">
                          A3 (ITÁLIA), BYOBLU (ITÁLIA), NHK WORLD - JAPAN
                          (JAPÃO), Multicanal Catamayo (EQUADOR), EducaTV
                          (EQUADOR), Al Iraqiya Al 3ama (IRÃ), TBS News (JAPÃO),
                          Canal Alpha Jura (SUÍÇA), Arirang Korea (COREIA DO
                          SUL), CTS (COREIA DO SUL), 3ABN (EUA), Al Jazeera
                          Árabe (CATAR), MBC Bollywood (EMIRADOS ÁRABES UNIDOS),
                          Samira TV (ARGÉLIA), Nabaa TV (LÍBANO, Alpenland TV
                          (SUÍÇA), Canal Málaga (ESPANHA), Calabria TV (ITÁLIA),
                          Somali National TV (SOMÁLIA), Aurora Arte (ITÁLIA),
                          Avivamiento (COLÔMBIA), STV2HD (ESLOVÁQUIA), Korean
                          Central Television (COREIA DO NORTE), Net TV
                          (ARGENTINA), Canal XFN (ARGENTINA), BVC (ARGENTINA),
                          Bloomberg TV (BÉLGICA), Notele (BÉLGICA), AVF Family
                          (BÉLGICA), VM Latino (COSTA RICA), 9XM (ÍNDIA), Atomic
                          Academy TV (ROMÊNIA), Exitosa (PERU), Alcance TV
                          (MÉXICO), B15 (MÉXICO), TV2 Nord (DINAMARCA), Estrella
                          Games (MÉXICO), Estrella News (MÉXICO), TV Midtvest
                          (DINAMARCA), Pura Palabra (Porto Rico), CDM
                          International (Porto Rico), Altenburg TV (ALEMANHA),
                          Ausbiz TV (AUSTRÁLIA), ABC TV (PARAGUAI), Canal,
                          Capital (COLÔMBIA), ANGEL TV (AUSTRÁLIA), AR TV Canal
                          Parlamento (PORTUGAL), 360 TV (REINO UNIDO), 1Almere
                          TV (HOLANDA), NTV News24 (JAPÃO), Adnkronos (ITÁLIA),
                          RED BULL TV (RÚSSIA), CHANNEL 86 (RÚSSIA), 1Twente TV
                          (UCRÂNIA), RTA Sports (AFEGANISTÃO), Ariana News
                          (AFEGANISTÃO), WTV Canal 20 (NICARAGUA), MTV Uutiset
                          (FINLÂNDIA), Canal TelePalmar (COLÔMBIA), Code Health
                          TV (BULGÁRIA), Berat TV (TURQUIA), Shop Channel
                          (JAPÃO), QVC Japan (JAPÃO), Gaki no Tsukai (JAPÂO),
                          Magic TV (BULGÁRIA), Tokyo MX1 (JAPÂO), Tokyo MX2
                          (JAPÂO), Weathernews (JAPÂO), Al Ghad (EGITO), Ticker
                          News (AUSTRÁLIA)
                        </p>
                      </div>
                    )}
                    <div className="input-plano">
                      <div className="div-input-paymentsinfo">
                        <button
                          className={`toggle-button ${
                            selectedButton === 'superFull' ? 'selected' : ''
                          }`}
                          type="button"
                          onClick={() => {
                            handleClick('336')
                            handleButtonClick('superFull', '13999')
                          }}
                          value={superFull}
                        >
                          <div className="div-choose-plano">
                            <img
                              src={wompfull}
                              className="img-channels"
                              alt="Descrição da imagem"
                            />

                            <div className="div-radio-plan">
                              <Label className="label1">R$ 139,99</Label>
                            </div>
                          </div>
                        </button>
                        <div className="div-value-plan">
                          <button
                            className="toggle-image-button"
                            type="button" // Add this attribute
                            onClick={toggleImageVisibility2}
                          >
                            SAIBA MAIS
                          </button>
                        </div>
                      </div>
                      {imageVisible2 && (
                        <div>
                          <h1 className="div-h1-title">CANAIS DISPONÍVEIS:</h1>
                          <h2 className="div-h2-sub">Abertos:</h2>
                          <p className="div-p">
                            TV Encontro das Águas, Record TV, TV A Crítica, Rede
                            Amazônica, TV Norte Amazonas, Rede, Brasil, Band,
                            RedeTV, Rede CNT, TVT, TV Brasil HD, TV Brasil 2
                          </p>
                          <h2 className="div-h2-sub">Agro:</h2>
                          <p className="div-p">
                            TV Milagro, Terra Viva, Canal do Boi, Agro Canal
                          </p>
                          <h2 className="div-h2-sub">Documentários:</h2>
                          <p className="div-p">
                            Animal Planet HD, Discovery Channel HD, Discovery
                            Science, Discovery World HD, Discovery Theater,
                            Discovery Turbo HD, Discovery Home & Health HD, Nat
                            Geo HD, History, History 2, CURTA!, Arte 1
                          </p>
                          <h2 className="div-h2-sub">Ecommerce:</h2>
                          <p className="div-p">Polishop</p>
                          <h2 className="div-h2-sub">Educativos:</h2>
                          <p className="div-p">
                            Educação Infantil e dos Anos Iniciais, Ensino Médio
                            e EJA, Anos Finais e EJA, TV Escola
                          </p>
                          <h2 className="div-h2-sub">Esportes:</h2>
                          <p className="div-p">
                            ESPN HD, ESPN 2 HD, ESPN 3 HD, ESPN 4 HD, ESPN
                            Extra, Fox Sports 2 HD, Band Sports HD
                          </p>
                          <h2 className="div-h2-sub">Filmes e Séries:</h2>
                          <p className="div-p">
                            ID - Investigação Discovery HD, FX HD, TNT HD, TNT
                            Séries, TCM HD, TBS, Space, CineBrasil TV, Paramount
                            Channel HD, Cinecanal HD, Warner, Canal Sony, AXN
                          </p>
                          <h2 className="div-h2-sub">Governamentais:</h2>
                          <p className="div-p">
                            TV Justiça, TV Câmara Municipal Manaus, TV
                            Assembleia, TV Câmara, TV Senado
                          </p>
                          <h2 className="div-h2-sub">Infantil e Animação:</h2>
                          <p className="div-p">
                            Disney Channel HD, Nick Jr., Nickelodeon HD,
                            Cartoonito, Tooncast, Zoomoo Kids, Discovery Kids
                            HD, Cartoon Network, TV Rá Tim Bum
                          </p>
                          <h2 className="div-h2-sub">Música:</h2>
                          <p className="div-p">
                            TV Tiradentes, MTV Live, MTV HD, Music Box Brazil
                          </p>
                          <h2 className="div-h2-sub">Notícias:</h2>
                          <p className="div-p">
                            Record News, TV Jovem Pan News, CNN Internacional,
                            Band News HD
                          </p>
                          <h2 className="div-h2-sub">Religiosos:</h2>
                          <p className="div-p">
                            12 Apóstolos, Boa Vontade, Rede Século 21, TV Pai
                            Eterno, Boas Novas, Rede Gênesis, RIT, TV Mundo
                            Maior, Ideal TV, Canção Nova, TV Novo Tempo, Rede
                            Vida, Rede Família, Rede Super, TV Aparecida
                          </p>
                          <h2 className="div-h2-sub">Variedades:</h2>
                          <p className="div-p">
                            Comedy Central, TV Cultura, Womp TV, Amazonsat, TV
                            Gazeta, SescTV, Canal Saúde, Fish TV, Lifetime, A&E,
                            E!, Woohoo, HGTV
                          </p>
                          <h2 className="div-h2-sub">Internacional:</h2>
                          <p className="div-p">
                            A3 (ITÁLIA), BYOBLU (ITÁLIA), NHK WORLD - JAPAN
                            (JAPÃO), Multicanal Catamayo (EQUADOR), EducaTV
                            (EQUADOR), Al Iraqiya Al 3ama (IRÃ), TBS News
                            (JAPÃO), Canal Alpha Jura (SUÍÇA), Arirang Korea
                            (COREIA DO SUL), CTS (COREIA DO SUL), 3ABN (EUA), Al
                            Jazeera Árabe (CATAR), MBC Bollywood (EMIRADOS
                            ÁRABES UNIDOS), Samira TV (ARGÉLIA), Nabaa TV
                            (LÍBANO, Alpenland TV (SUÍÇA), Canal Málaga
                            (ESPANHA), Calabria TV (ITÁLIA), Somali National TV
                            (SOMÁLIA), Aurora Arte (ITÁLIA), Avivamiento
                            (COLÔMBIA), STV2HD (ESLOVÁQUIA), Korean Central
                            Television (COREIA DO NORTE), Net TV (ARGENTINA),
                            Canal XFN (ARGENTINA), BVC (ARGENTINA), Bloomberg TV
                            (BÉLGICA), Notele (BÉLGICA), AVF Family (BÉLGICA),
                            VM Latino (COSTA RICA), 9XM (ÍNDIA), Atomic Academy
                            TV (ROMÊNIA), Exitosa (PERU), Alcance TV (MÉXICO),
                            B15 (MÉXICO), TV2 Nord (DINAMARCA), Estrella Games
                            (MÉXICO), Estrella News (MÉXICO), TV Midtvest
                            (DINAMARCA), Pura Palabra (Porto Rico), CDM
                            International (Porto Rico), Altenburg TV (ALEMANHA),
                            Ausbiz TV (AUSTRÁLIA), ABC TV (PARAGUAI), Canal,
                            Capital (COLÔMBIA), ANGEL TV (AUSTRÁLIA), AR TV
                            Canal Parlamento (PORTUGAL), 360 TV (REINO UNIDO),
                            1Almere TV (HOLANDA), NTV News24 (JAPÃO), Adnkronos
                            (ITÁLIA), RED BULL TV (RÚSSIA), CHANNEL 86 (RÚSSIA),
                            1Twente TV (UCRÂNIA), RTA Sports (AFEGANISTÃO),
                            Ariana News (AFEGANISTÃO), WTV Canal 20 (NICARAGUA),
                            MTV Uutiset (FINLÂNDIA), Canal TelePalmar
                            (COLÔMBIA), Code Health TV (BULGÁRIA), Berat TV
                            (TURQUIA), Shop Channel (JAPÃO), QVC Japan (JAPÃO),
                            Gaki no Tsukai (JAPÂO), Magic TV (BULGÁRIA), Tokyo
                            MX1 (JAPÂO), Tokyo MX2 (JAPÂO), Weathernews (JAPÂO),
                            Al Ghad (EGITO), Ticker News (AUSTRÁLIA)
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </InputArea>
              {paymentVisible && (
                <div className="button-container">
                  <Button type="button" onClick={handleBack}>
                    Voltar
                  </Button>
                  <Button className="inputButton" type="submit">
                    Ir Para Pagamento
                  </Button>
                </div>
              )}
              {loading && (
                <div
                  className="div-loading-pagamento"
                  style={{ textAlign: 'center' }}
                >
                  <img
                    style={{ width: '15em' }}
                    src={loadingImage}
                    alt="Carregando..."
                  />
                  <div className="div-pagamento">
                    <p className="div-p-pagamento">Aguardando Pagamento.</p>
                    <p className="div-p-pagamento">
                      Assim que o pagamento for concluído volte para esta tela.
                    </p>
                  </div>
                </div>
              )}
              {step === 3 && (
                <div>
                  <p>teste</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </FormContainer>
  )
}

export default Form
