import GlobalStyle from './styles/global'
import styled from 'styled-components'
import Form from './components/Form.js'
import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import logo from './images/wompplay2023.png'

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

function App() {
  return (
    <>
      <Container>
        <img src={logo} alt="Logo" width="45%" />

        <Form />
      </Container>

      <ToastContainer autoClose={3000} position={toast.POSITION.BOTTOM_LEFT} />
      <GlobalStyle />
    </>
  )
}

export default App
